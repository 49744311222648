<template>
<div class="page home">
    <div class="col-md-3 col-xs-12 home-sidebar "  :class="{'fade-in': activeSection !=0 , 'fade-out': activeSection==0  ,  }">
        <div class="carousel-container">
                    <div class=" carousel-context" :class="{ 'slide-in-top' : activeSection == 1 && slideDirection == 'down' ,
                                                            'slide-in-bottom' : activeSection == 1 && slideDirection == 'up',
                                                            'slide-in-bottom' : activeSection == 1 && slideDirection == 'up',
                                                            'slide-in-bottom' : activeSection == 1 && slideDirection == 'up' ,
                                                            'slide-center-to-bottom' : leaveSection == 1 && slideDirection == 'down',
                                                            'slide-center-to-top' : leaveSection == 1 && slideDirection == 'up'  }"   >
                        <div v-html="translate[language].slide1" ></div>
                    </div>
              
                    <div class="carousel-context"  :class="{ 'slide-in-top' : activeSection == 2 && slideDirection == 'down', 
                                                            'slide-in-bottom' : activeSection == 2 && slideDirection == 'up',
                                                            'slide-in-bottom' : activeSection == 2 && slideDirection == 'up',
                                                            'slide-in-bottom' : activeSection == 2 && slideDirection == 'up',
                                                            'slide-center-to-bottom' : leaveSection == 2 && slideDirection == 'down',
                                                            'slide-center-to-top' : leaveSection == 2 && slideDirection == 'up'  }" >
                        <div v-html="translate[language].slide2" ></div>
                    </div>
            
                    <div class="carousel-context" :class="{ 'slide-in-top' : activeSection == 3 && slideDirection == 'down' ,
                                                        'slide-in-top' : activeSection == 3 && slideDirection == 'down' ,
                                                        'slide-in-bottom' : activeSection == 3 && slideDirection == 'up',
                                                        'slide-in-top' : activeSection == 3 && slideDirection == 'down' ,
                                                        'slide-center-to-bottom' : leaveSection == 3 && slideDirection == 'down',
                                                        'slide-center-to-top' : leaveSection == 3 && slideDirection == 'up'  } ">
                        <div v-html="translate[language].slide3" ></div>
                    </div>
            </div>
        </div>
    <full-page ref="fullpage" :options="options">
        <div class="section fade-in " >
            <div class="home home-center">
                <div class="home-background" :class="{'scale-mid' :  activeSection==0}"></div>
                <div class="col-md-12" style="height:75%; position:relative">
                    <div style="display: table; height: 100%;margin: 0 auto; width: 90%">
                         <h1 class="font-white center" style="display: table-cell; vertical-align:middle; align-content: center; font-family: 'Montserrat', sans-serif; font-weight: 300;"  v-html="translate[language].intro" ></h1>
                    </div>
                    <div class="bounce-top button-home-intro-container">
                        <a href="#" @click="$refs.fullpage.api.moveSectionDown()" aria-label="navigation-bottom-section-0">
                            <img class="navigation-down" src="/images/scroll-icon.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="section fade-in">
            <div class="row home ">
                 <div class="col-md-12 home-right nomargin ">
                    <div class=" home-right-1" :class="{'scale-mid' :  activeSection==1}" />
                    <div class="button-home-container bounce-top">
                        <a href="#" @click="$refs.fullpage.api.moveSectionDown()" aria-label="navigation-bottom-section-1">
                            <img class="navigation-down" src="/images/scroll-icon.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="row home ">
                <div class="col-md-12 home-right nomargin ">
                    <div class=" home-right-2" :class="{'scale-mid' :  activeSection==2}" />
                        <div class="button-home-container bounce-top">
                            <a href="#" @click="$refs.fullpage.api.moveSectionDown()" aria-label="navigation-bottom-section-2">
                                <img class="navigation-down" src="/images/scroll-icon.png" alt="">
                            </a>
                        </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="row home ">
                <div class="col-md-12 home-right nomargin ">
                    <div class=" home-right-3"  :class="{'scale-mid' :  activeSection==3}" />
                     <div class="button-home-container bounce-top">
                        <a href="#" @click="$refs.fullpage.api.moveTo(1)" aria-label="navigation-bottom-section-3">
                            <img class="navigation-up" src="/images/scroll-icon.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </full-page>
</div>
</template>
<script>
export default {
    data(){
        return {
            interval : null,
            activeSection: 0,
            leaveSection: 0,
            slideDirection: 'down',
            language : ( this.$cookies.get('language') == null  ? 'english'  : this.$cookies.get('language') ),
            options: {
                // afterLoad: this.afterLoad,
                scrollBar: false,
                responsiveSlides: true,
                easingcss3 : 'cubic-bezier(0.455, 0.310, 0.120, 1.020)',
                licenseKey: '',
                scrollingSpeed : 1000,
                fitToSectionDelay: 2000,
                onLeave: this.onLeave,
                css3: true,
	
            },
            translate : {
                spanish: {
                    intro: 'Generamos y desarrollamos proyectos inmobiliarios exitosos con un impacto positivo',
                    slide1: '<p> Generamos certidumbre</p> <p> <strong> invirtiendo en proyectos con retornos sólidos </strong> </p>',
                    slide2: '<p> Sabemos encontrar</p> <p>oportunidades en </p> <p> <strong> tiempos RETANTES</strong> </p> ',
                    slide3: '<p> HAN Capital tiene el objetivo de</p> <p> <strong> generar riqueza</strong> </p><p>y generar el bien común </p>',
                },
                english: {
                    intro: 'We generate and develop successful real estate projects with a positive impact',
                    slide1: '<p> WE create certainty by investing and developing </p> <p> <strong> projects with solid</strong></p> <p> returns in proven markets </p>',
                    slide2: '<p> WE KNOW HOW TO FIND OPPORTUNITIES IN </p> <p> <strong>CHALLENGING TIMES </strong> </p> <p> CREATING VALUE FOR OUR INVESTORS  </p>',
                    slide3: '<p> HAN CAPITAL’S  GOAL  IS  TO  </p> <p> GENERATE </p> <p> <strong> WEALTH  AND  PROSPERITY </strong> </p> ',
                }
            }
        }
    },
    methods:{
        onLeave( origin, destination, direction){
          
            this.leaveSection = origin.index
            this.activeSection = destination.index
            this.slideDirection = ( direction ? direction : 'down' )

        },
        setIsActive( integer ){
            this.isActive = integer
        },
        checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
        checkLoadImage(){

        },
        
    },
    created(){
        this.checkLanguage()
        this.checkLoadImage()
    }
    
}
</script>

